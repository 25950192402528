import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppInfoModel, UrlConfigModel } from '@models/shared/shared-models';
import { of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class AppInitService {

    constructor(private httpClient: HttpClient) {

    }

    getUrlModel(buildVersion: AppInfoModel, urlModel: UrlConfigModel) {
        if (urlModel.overrideUrl)
            return of(urlModel);

        return of(urlModel);
    }

    getBuildVersion() {
        const salt = (new Date()).getTime();
        let url = `/assets/appInfo.json?${salt}`;
        return this.httpClient
            .get<AppInfoModel>(url);
    }
}