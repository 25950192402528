import { AppInfoModel } from '@models/shared/shared-models';
import { apiUrlStore } from "./api-url-store";

export const environment = {
    name: 'prd',
    hmr: false,
    userId: 0,
    urlStore: apiUrlStore,
    appInfo: {} as AppInfoModel,
    isProd() {
        return this.name === 'prd';
    }
};
