import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import {
    BaseServiceResponse,
    ServiceResponseError,
    ServiceResponseErrorType,
    ValidationErrorItem,
    ValidationSeverity
} from '@models/shared/base-api-models';
import { groupBy, GroupResult } from '@progress/kendo-data-query';
import { NotificationService } from "@services/shared/notification.service";

@Injectable({
    providedIn: 'root'
})
export class AppErrorService {

    constructor(private notificationService: NotificationService) {
    }

    handleFormError(formGroup: FormGroup, error: HttpErrorResponse) {
        this.clearFormValidationError(formGroup, 'validationErrors');
        formGroup.markAllAsTouched();
        let parsedError = this.parseError(error);
        if (parsedError.errorType == ServiceResponseErrorType.ValidationError) {
            let validationItems = parsedError.validationErrors?.propertyValidations;
            if (validationItems)
                this.settleValidationError(validationItems, formGroup);
        } else {
            this.notificationService.error(`${parsedError.errorMessage} - ${parsedError.reasonPhrase}`);
        }
    }

    settleValidationError(validationItems: ValidationErrorItem[], formGroup: FormGroup) {
        //Push all errors that we can find control for by name
        let groupedItems = groupItems(validationItems);
        let unidentifiedItems = settleFormErrors(groupedItems);
        settleFormErrors(unidentifiedItems);

        function settleFormErrors(itemsToSettle: Map<string, ValidationErrorItem[]>) {
            let unidentifiedItems = new Map<string, ValidationErrorItem[]>();
            let propNames = itemsToSettle.keys();
            for (let propName of propNames) {
                let propErrors = itemsToSettle.get(propName) ?? [];
                propErrors = propErrors.filter(x => x.severity == ValidationSeverity.Error);
                if (propErrors.length > 0) {
                    let control = formGroup.get(propName);
                    if (control) {
                        if (control.errors) {
                            control.errors['validationErrors'] = propErrors;
                            control.setErrors(control.errors);
                        } else {
                            control.setErrors({
                                validationErrors: propErrors
                            });
                        }
                    } else {
                        let firstHalf = parseFirstHalf(propName);
                        let subPropErrors = unidentifiedItems.get(firstHalf) ?? [];
                        propErrors.forEach(value => {
                            value.propertyName = parseLastHalf(value.propertyName);
                            subPropErrors.push(value);
                        });
                        unidentifiedItems.set(firstHalf, subPropErrors);
                    }
                }
            }

            return unidentifiedItems;
        }

        function parseFirstHalf(propName: string) {
            return propName.split('.')[0];
        }

        function parseLastHalf(propName: string) {
            let propArr = propName.split('.');
            if (propArr.length <= 1)
                return propName;

            propArr.shift();
            return propArr.join('.');
        }

        function groupItems(validationItems: ValidationErrorItem[]) {
            let groupResults = groupBy(validationItems, [ { field: 'propertyName' } ]) as GroupResult[];
            const returnDict = new Map<string, ValidationErrorItem[]>();
            groupResults.forEach(groupResult => returnDict.set(groupResult.value, groupResult.items as ValidationErrorItem[]));
            return returnDict;
        }
    }

    clearFormValidationError(control: AbstractControl, key?: string) {
        if (control instanceof FormGroup) {
            let castedControl = control as FormGroup;
            //clearError(castedControl, key);
            let controlKeys = Object.keys(castedControl.controls);
            controlKeys.forEach(controlKey => {
                let subControl = castedControl.get(controlKey);
                if (subControl)
                    this.clearFormValidationError(subControl, key);
            });
        } else if (control instanceof FormArray) {
            let castedControl = control as FormArray;
            //clearError(castedControl, key);
            let controlKeys = Object.keys(castedControl.controls);
            controlKeys.forEach(controlKey => {
                let subControl = castedControl.get(controlKey);
                if (subControl)
                    this.clearFormValidationError(subControl, key);
            });
        } else {
            clearError(control, key);
        }

        function clearError(myControl: AbstractControl, myKey?: string) {
            if (myKey) {
                if (myControl.hasError(myKey)) {
                    let hasErrorsLeft = false;
                    if (myControl && myControl.errors) {
                        delete myControl.errors[myKey];
                        hasErrorsLeft = Object.keys(myControl.errors).length > 0;
                    }

                    hasErrorsLeft ?
                        myControl.setErrors(myControl.errors) :
                        myControl.setErrors(null);
                }
            } else {
                if (myControl.errors)
                    myControl.setErrors(null);
            }
        }
    }

    handleError(error: HttpErrorResponse, fromInterceptor?: boolean) {
        let parsedError = this.parseError(error);
        if (parsedError.errorType == ServiceResponseErrorType.ValidationError && !fromInterceptor) {
            let propValList =
                parsedError.validationErrors?.propertyValidations ?? [];
            propValList = propValList.filter(x => x.severity == ValidationSeverity.Error);
            let errorList = propValList.map(value => `${value.propertyName} - ${value.errorMessage}`);
            if (errorList.length > 0) {
                let errorStr = errorList?.join('<br/>');
                this.notificationService.error(parsedError.reasonPhrase);
            }
        } else if (parsedError.statusCode == HttpStatusCode.Forbidden ||
            parsedError.statusCode == HttpStatusCode.Unauthorized) {
            this.notificationService.error('Unauthorized Request');
        } else if (parsedError.statusCode == HttpStatusCode.NotFound) {
            if (!error.url?.contains('microsoft.com'))
                this.notificationService.error('Page Not Found');
        } else {
            this.notificationService.error(parsedError.reasonPhrase);
        }
    }

    parseError(error: HttpErrorResponse): ServiceResponseError {
        let baseResp = error.error as BaseServiceResponse;
        if (baseResp?.error?.errorType)
            return baseResp.error;

        let apiError = error?.error as ServiceResponseError;
        if (apiError?.errorType)
            return apiError;

        return {
            errorMessage: 'Unknown Error',
            reasonPhrase: 'An unknown error occurred',
            statusCode: error.status,
            errorType: ServiceResponseErrorType.UnKnownApiException
        }
    }
}