import { EnvironmentProviders, Provider } from '@angular/core';
import { provideToastr } from "ngx-toastr";

export const getNotificationProvider = (): Array<Provider | EnvironmentProviders> => {
    return [
        provideToastr({
            autoDismiss: true,
            closeButton: true,
            preventDuplicates: true,
            maxOpened: 2,
            progressBar: true
        })
    ];
};
