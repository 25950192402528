import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'health-check',
    standalone: true,
    template: 'works'
})
export class HealthCheckComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
