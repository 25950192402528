import { NgIf } from '@angular/common';
import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { FuseLoadingBarComponent } from '@fuse/components/loading-bar';
import { Subject } from 'rxjs';
import { DialogsModule } from "@progress/kendo-angular-dialog";
import { ComboItemTemplateComponent } from "@shared/components/combo-item-template/combo-item-template.component";

@Component({
    selector: 'empty-layout',
    templateUrl: './empty.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [ FuseLoadingBarComponent, NgIf, RouterOutlet, DialogsModule, ComboItemTemplateComponent ],
})
export class EmptyLayoutComponent implements OnDestroy {
    private destroy: Subject<any> = new Subject<any>();

    ngOnDestroy(): void {
        this.destroy.next(null);
        this.destroy.complete();
    }
}
