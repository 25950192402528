<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<ng-container *ngIf="isScreenSmall && navigation != null">
    <fuse-vertical-navigation
            [mode]="'over'"
            [name]="'mainNavigation'"
            [navigation]="navigation.default"
            [opened]="false"
            class="dark bg-gray-900 print:hidden">
        <!-- Navigation header hook -->
        <ng-container fuseVerticalNavigationContentHeader>
            <!-- Logo -->
            <div class="flex items-center h-20 pt-6 px-8">
                <div class="text-2xl">
                    Item Patrol
                </div>
            </div>
        </ng-container>
    </fuse-vertical-navigation>
</ng-container>

<!-- Wrapper -->
<div class="flex flex-col flex-auto items-center w-full min-w-0 bg-gray-200 dark:bg-card">

    <!-- Header -->
    <div class="relative flex justify-center w-full overflow-hidden z-49 bg-primary-700 print:hidden">
        <div class="max-w-360 w-full sm:py-3 sm:m-8 sm:mb-0 md:mt-12 md:mx-8 md:pt-4 md:pb-3 sm:rounded-t-xl border-b sm:shadow-2xl overflow-hidden bg-card">
            <!-- Top bar -->
            <div class="relative flex flex-auto flex-0 items-center h-16 px-4 md:px-6">
                <!-- Logo -->
                <ng-container *ngIf="!isScreenSmall">
                    <div class="flex items-center mx-2">
                        <img alt="Item Patrol" class="dark:hidden"
                             src="assets/images/logo/app-logo-dark.png"
                             style="height: 75px;">
                        <img alt="Item Patrol" class="hidden dark:block"
                             src="assets/images/logo/app-logo-light.png"
                             style="height: 75px;">
                    </div>
                </ng-container>
                <!-- Navigation toggle button -->
                <ng-container *ngIf="isScreenSmall">
                    <button
                            (click)="toggleNavigation('mainNavigation')"
                            mat-icon-button>
                        <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
                    </button>
                </ng-container>
                <!-- Components -->
                <div class="flex items-center pl-2 ml-auto space-x-1 sm:space-x-2">
                    <theme-switch></theme-switch>
                    <user></user>
                </div>
            </div>
            <!-- Bottom bar -->
            <ng-container *ngIf="!isScreenSmall && navigation != null">
                <div class="relative flex flex-auto flex-0 items-center h-16 px-4">
                    <fuse-horizontal-navigation
                            [name]="'mainNavigation'"
                            [navigation]="navigation.horizontal"></fuse-horizontal-navigation>
                </div>
            </ng-container>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-auto justify-center w-full sm:px-8">
        <div class="flex flex-col flex-auto p-5 w-full sm:max-w-360 sm:shadow-xl sm:overflow-hidden bg-default">
            <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
                 Otherwise, layout changes won't be registered and the view won't be updated! -->
            <div kendoDialogContainer></div>
            <combo-item-template></combo-item-template>
            <router-outlet *ngIf="true"></router-outlet>
        </div>
    </div>

    <!-- Footer -->
    <div class="relative flex justify-center w-full z-49 print:hidden">
        <div class="flex items-center max-w-360 w-full h-14 sm:h-20 px-6 md:px-8 sm:shadow-xl border-t bg-card dark:bg-default">
            <span class="font-medium text-secondary">Item Patrol, Inc. - &copy; {{ currentYear }}</span>
        </div>
    </div>

</div>
