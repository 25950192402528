import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppNav } from "@models/shared/navigation.models";
import { horizontalNavigation } from "@helpers/constants/menu.constants";
import { AuthService } from "@auth0/auth0-angular";
import { map } from "rxjs/operators";
import { jwtDecode } from "jwt-decode";
import { UserAccountService } from "@services/pages/user-account.service";

@Injectable({ providedIn: 'root' })
export class NavigationService {

    constructor(private userAccountService: UserAccountService) {

    }

    get(): Observable<AppNav> {
        return this.userAccountService
            .getAllPermissions()
            .pipe(map(permissions => {
                let navItems = [];
                horizontalNavigation.forEach(menuItem => {
                    let perms = menuItem?.permissions ?? [];
                    if (!perms.length) {
                        navItems.push(menuItem);
                    } else {
                        let unMatchedPerms =
                            perms.filter(x => !permissions.includes(x));
                        if (!unMatchedPerms.length)
                            navItems.push(menuItem);
                    }
                });

                return {
                    horizontal: navItems,
                    compact: navItems,
                    default: navItems,
                    futuristic: navItems
                } as AppNav;
            }));
    }
}
