import { Injectable } from '@angular/core';
import { ThemeModel } from '@models/shared/theme.models';
import { FuseConfigService } from "@fuse/services/config";

@Injectable({ providedIn: 'root' })
export class ThemeService {
    constructor(private fuseConfig: FuseConfigService) {
    }

    getCurrentTheme(): ThemeModel {
        let settingVal = localStorage.getItem('ec.patient.care.v1.themeSettings');
        if (!settingVal) {
            let defaultModel = new ThemeModel();
            this.saveCurrentTheme(defaultModel);
            settingVal = JSON.stringify(defaultModel);
        }

        return JSON.parse(settingVal);
    }

    saveCurrentTheme(themeModel: ThemeModel,
                     skipLoading = false) {
        let settingVal = JSON.stringify(themeModel);
        localStorage.setItem('ec.patient.care.v1.themeSettings', settingVal);
        if (!skipLoading)
            this.loadThemeInBrowser();
    }

    loadThemeInBrowser() {
        let currentModel = this.getCurrentTheme();
        this.fuseConfig.config = {
            scheme: currentModel.ColorScheme,
            theme: currentModel.Theme,
            layout: currentModel.Layout
        };
    }
}