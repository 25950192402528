import { bootstrapApplication } from '@angular/platform-browser';
import './app/core/helpers/extensions/string-extension'
import { AppComponent } from 'app/app.component';
import { appConfig } from 'app/app.config';
import { environment } from "@env/environment";
import { enableProdMode } from "@angular/core";
import 'hammerjs';

if (environment.isProd()) {
    enableProdMode();
}

bootstrapApplication(AppComponent, appConfig)
    .catch(err => console.error(err));
