<div *ngIf="userData != null">
    <button [matMenuTriggerFor]="userActions" mat-icon-button>
        <span class="relative">
            <img [alt]="userData.name" [src]="userData.picture"
                 class="w-7 h-7 rounded-full">
        </span>
    </button>

    <mat-menu #userActions="matMenu" [xPosition]="'before'">
        <button [routerLink]="'/secure-area/user-edit'" mat-menu-item>
            <span class="flex flex-col leading-none">
                <span>Signed in as</span>
                <span class="mt-1.5 text-md font-medium">{{ userData.name }}</span>
            </span>
        </button>
        <button [routerLink]="'/secure-area/contact-us'"
                mat-menu-item>
            <mat-icon [svgIcon]="'heroicons_outline:phone'"></mat-icon>
            <span>Contact Us</span>
        </button>
        <mat-divider class="my-2"></mat-divider>
        <button
                (click)="signOut()"
                mat-menu-item>
            <mat-icon [svgIcon]="'heroicons_outline:arrow-right-on-rectangle'"></mat-icon>
            <span>Sign out</span>
        </button>
    </mat-menu>
</div>