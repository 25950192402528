import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { UrlConfigModel } from "@models/shared/shared-models";

@Injectable()
export class UrlMagicHttpInterceptor implements HttpInterceptor {

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        let urlConfig = this.getUrlConfig(request.url);
        if (!urlConfig)
            return next.handle(request);

        let newUrl = this.substituteUrl(urlConfig, request.url);

        request = request.clone({
            url: newUrl
        });

        return next.handle(request);
    }

    private getUrlConfig(url: string) {
        if (!url)
            return undefined;

        if (!url.startsWith('@'))
            return undefined;

        let alias = url;
        let urlIndex = url.indexOf('/');
        if (urlIndex !== -1)
            alias = url.substring(0, urlIndex);

        return environment.urlStore.find(value => value.alias === alias);
    }

    private substituteUrl(record: UrlConfigModel, url: string) {
        if (!record.overrideUrl)
            return url;

        let aliasReplacement = record.overrideUrl;
        if (record.relativeUrl)
            aliasReplacement = aliasReplacement.combineUrl(record.relativeUrl);

        return url.replace(record.alias, aliasReplacement);
    }
}
