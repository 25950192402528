import { ENVIRONMENT_INITIALIZER, EnvironmentProviders, inject, Provider } from '@angular/core';
import { IconsService } from "@helpers/icons/icons.service";
import { ICON_SETTINGS } from "@progress/kendo-angular-icons";

export const getIconsProvider = (): Array<Provider | EnvironmentProviders> => {
    return [
        {
            provide: ENVIRONMENT_INITIALIZER,
            useValue: () => inject(IconsService),
            multi: true,
        },
        {
            provide: ICON_SETTINGS,
            useValue: {
                type: "svg",
                size: "small"
            }
        }
    ];
};
