<ng-template #itemTemplate kendoComboBoxItemTemplate let-dataItem>
    <ng-template [ngForOf]="dataItem.templateDataParsed" let-item ngFor>
        <ng-container *ngIf="item.key !== '_'">
            {{ item.key }}: {{ item.value }}
        </ng-container>
        <ng-container *ngIf="item.key === '_'">
            {{ item.value }}
        </ng-container>
        <br/>
    </ng-template>
</ng-template>