import { Component, OnInit } from '@angular/core';
import { ThemeService } from "@services/layout/theme.service";
import { ColorScheme } from "@models/shared/theme.models";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { NgIf } from "@angular/common";

@Component({
    selector: 'theme-switch',
    templateUrl: 'theme-switch.component.html',
    standalone: true,
    imports: [
        MatButtonModule,
        MatIconModule,
        NgIf
    ]
})
export class ThemeSwitchComponent implements OnInit {

    inDarkMode: boolean = false;

    constructor(
        private themeService: ThemeService
    ) {
    }

    ngOnInit() {
        let currentTheme = this.themeService.getCurrentTheme();
        this.inDarkMode = currentTheme.ColorScheme == ColorScheme.Dark;
    }

    toggleTheme() {
        let currentTheme = this.themeService.getCurrentTheme();
        currentTheme.ColorScheme =
            currentTheme.ColorScheme == ColorScheme.Light ? ColorScheme.Dark : ColorScheme.Light;
        this.themeService.saveCurrentTheme(currentTheme);
        this.inDarkMode = currentTheme.ColorScheme == ColorScheme.Dark;
    }
}
