<!-- Item wrapper -->
<div
        [class.fuse-horizontal-navigation-item-has-subtitle]="!!item.subtitle"
        [ngClass]="item.classes?.wrapper"
        class="fuse-horizontal-navigation-item-wrapper">

    <!-- Item with an internal link -->
    <ng-container *ngIf="item.link && !item.externalLink && !item.function && !item.disabled">
        <div
                [fragment]="item.fragment ?? null"
                [matTooltip]="item.tooltip || ''"
                [ngClass]="{'fuse-horizontal-navigation-item-active-forced': item.active}"
                [preserveFragment]="item.preserveFragment ?? false"
                [queryParamsHandling]="item.queryParamsHandling ?? null"
                [queryParams]="item.queryParams ?? null"
                [routerLinkActiveOptions]="isActiveMatchOptions"
                [routerLinkActive]="'fuse-horizontal-navigation-item-active'"
                [routerLink]="[item.link]"
                class="fuse-horizontal-navigation-item">
            <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
        </div>
    </ng-container>

    <!-- Item with an external link -->
    <ng-container *ngIf="item.link && item.externalLink && !item.function && !item.disabled">
        <a
                [href]="item.link"
                [matTooltip]="item.tooltip || ''"
                [target]="item.target || '_self'"
                class="fuse-horizontal-navigation-item">
            <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
        </a>
    </ng-container>

    <!-- Item with a function -->
    <ng-container *ngIf="!item.link && item.function && !item.disabled">
        <div
                (click)="item.function(item)"
                [matTooltip]="item.tooltip || ''"
                [ngClass]="{'fuse-horizontal-navigation-item-active-forced': item.active}"
                class="fuse-horizontal-navigation-item">
            <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
        </div>
    </ng-container>

    <!-- Item with an internal link and function -->
    <ng-container *ngIf="item.link && !item.externalLink && item.function && !item.disabled">
        <div
                (click)="item.function(item)"
                [fragment]="item.fragment ?? null"
                [matTooltip]="item.tooltip || ''"
                [ngClass]="{'fuse-horizontal-navigation-item-active-forced': item.active}"
                [preserveFragment]="item.preserveFragment ?? false"
                [queryParamsHandling]="item.queryParamsHandling ?? null"
                [queryParams]="item.queryParams ?? null"
                [routerLinkActiveOptions]="isActiveMatchOptions"
                [routerLinkActive]="'fuse-horizontal-navigation-item-active'"
                [routerLink]="[item.link]"
                class="fuse-horizontal-navigation-item">
            <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
        </div>
    </ng-container>

    <!-- Item with an external link and function -->
    <ng-container *ngIf="item.link && item.externalLink && item.function && !item.disabled">
        <a
                (click)="item.function(item)"
                [href]="item.link"
                [matTooltip]="item.tooltip || ''"
                [target]="item.target || '_self'"
                class="fuse-horizontal-navigation-item"
                mat-menu-item>
            <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
        </a>
    </ng-container>

    <!-- Item with a no link and no function -->
    <ng-container *ngIf="!item.link && !item.function && !item.disabled">
        <div
                [matTooltip]="item.tooltip || ''"
                [ngClass]="{'fuse-horizontal-navigation-item-active-forced': item.active}"
                class="fuse-horizontal-navigation-item">
            <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
        </div>
    </ng-container>

    <!-- Item is disabled -->
    <ng-container *ngIf="item.disabled">
        <div class="fuse-horizontal-navigation-item fuse-horizontal-navigation-item-disabled">
            <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
        </div>
    </ng-container>

</div>

<!-- Item template -->
<ng-template #itemTemplate>

    <!-- Icon -->
    <ng-container *ngIf="item.icon">
        <mat-icon
                [ngClass]="item.classes?.icon"
                [svgIcon]="item.icon"
                class="fuse-horizontal-navigation-item-icon"></mat-icon>
    </ng-container>

    <!-- Title & Subtitle -->
    <div class="fuse-horizontal-navigation-item-title-wrapper">
        <div class="fuse-horizontal-navigation-item-title">
            <span [ngClass]="item.classes?.title">
                {{ item.title }}
            </span>
        </div>
        <ng-container *ngIf="item.subtitle">
            <div class="fuse-horizontal-navigation-item-subtitle text-hint">
                <span [ngClass]="item.classes?.subtitle">
                    {{ item.subtitle }}
                </span>
            </div>
        </ng-container>
    </div>

    <!-- Badge -->
    <ng-container *ngIf="item.badge">
        <div class="fuse-horizontal-navigation-item-badge">
            <div
                    [ngClass]="item.badge.classes"
                    class="fuse-horizontal-navigation-item-badge-content">
                {{ item.badge.title }}
            </div>
        </div>
    </ng-container>

</ng-template>
