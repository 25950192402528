import { HttpStatusCode } from '@angular/common/http';

export interface BaseServiceResponse {
    requestId?: string;
    error: ServiceResponseError;
    responseData?: any;
    responseMessage?: string;
}

export enum ServiceResponseErrorType {
    None = 0,
    UnKnownApiException = 1,
    KnownApiException = 2,
    ClientProxyException = 3,
    ValidationError = 4,
}

export class ServiceResponseError {
    reasonPhrase?: string;
    errorMessage!: string;
    errorJSON?: any;
    stackTrace?: any;
    validationErrors?: ApiValidationErrors;
    additionalErrorData?: any;
    errorType!: ServiceResponseErrorType;
    statusCode!: HttpStatusCode;
}

export interface ApiValidationErrors {
    propertyValidations: ValidationErrorItem[];
}

export interface ValidationErrorItem {
    propertyName: string;
    errorMessage: string;
    severity?: ValidationSeverity;
}

export enum ValidationSeverity {
    Error = 0,
    Warning = 1,
    Info = 2
}