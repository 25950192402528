import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from "@angular/router";

@Injectable()
export class FromAdminInterceptor implements HttpInterceptor {

    constructor(private router: Router) {
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        let url = this.router.url;
        if (url.indexOf("/secure-area/user-list") === 0) {
            request = request.clone({
                setHeaders: {
                    "fromAdmin": "true"
                }
            });

            return next.handle(request);
        } else {
            return next.handle(request);
        }
    }
}
