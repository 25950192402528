export class ThemeModel {
    Layout = LayoutMode.Material;
    ColorScheme = ColorScheme.Dark;
    Theme = Theme.Brand;
}

export enum Theme {
    Brand = 'theme-brand'
}

export enum ColorScheme {
    Light = 'light',
    Dark = 'dark'
}

export enum LayoutMode {
    Material = 'material'
}