import { ResolveFn } from '@angular/router';
import { of, tap } from "rxjs";
import { inject } from "@angular/core";
import { UserAccountService } from "@services/pages/user-account.service";
import { environment } from "@env/environment";
import { map } from "rxjs/operators";

export const auth0Resolver: ResolveFn<number> =
    () => {
        let userAccountService = inject(UserAccountService);
        if (environment.userId)
            return of(environment.userId);

        return userAccountService
            .addUpdate()
            .pipe(tap(value => {
                environment.userId = value
            }))
            .pipe(map(x => (x.userId ?? 0) as number));
    };
