import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppErrorService } from '@services/shared/app-error.service';

export class ErrorInterceptor implements HttpInterceptor {

    constructor(private appErrorService: AppErrorService) {
    }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let reqHeaders = req.headers;
        if (!reqHeaders.has('warning-allowed'))
            reqHeaders = reqHeaders.append('warning-allowed', '1');

        let newReq = req.clone({
            headers: reqHeaders
        });

        return next.handle(newReq).pipe(
            tap(
                () => {
                },
                (err: any) => {
                    if (err instanceof HttpErrorResponse) {
                        this.appErrorService.handleError(err, true);
                    }
                })
        );
    }
}