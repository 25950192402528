import { EnvironmentProviders, importProvidersFrom, Provider } from '@angular/core';
import { AuthHttpInterceptor, AuthModule } from "@auth0/auth0-angular";
import { HTTP_INTERCEPTORS } from "@angular/common/http";

export const getAuth0Provider = (): Array<Provider | EnvironmentProviders> => {
    return [
        importProvidersFrom(
            AuthModule.forRoot({
                domain: 'apprig.us.auth0.com',
                clientId: 'Wp4vmQjhCZ2WyJDXQYatzonivZSb2fcy',
                authorizationParams: {
                    redirect_uri: window.location.origin,
                    audience: "https://myapprig.com/prd-svc-item-patrol"
                },
                httpInterceptor: {
                    allowedList: [
                        "https://api.itempatrol.com/api/*",
                        "http://localhost:7095/api/*"
                    ]
                }
            })),
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthHttpInterceptor,
            multi: true,
        }
    ];
};
