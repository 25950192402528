import { Route } from '@angular/router';
import { LayoutComponent } from 'app/layout/layout.component';
import { HealthCheckComponent } from "@shared/components/health-check/health-check.component";
import { AuthGuard } from "@auth0/auth0-angular";
import { auth0Resolver } from "@helpers/resolvers/auth0.resolver";

export let appRoutes: Route[];
appRoutes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'open-area'
    },
    {
        path: 'ar_healthcheck',
        pathMatch: 'full',
        component: HealthCheckComponent,
    },
    {
        path: 'open-area',
        pathMatch: 'prefix',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: '', loadChildren: () => import('app/pages/open-area/open-area.routes') }
        ]
    },
    {
        path: 'secure-area',
        pathMatch: 'prefix',
        canActivate: [ AuthGuard ],
        canActivateChild: [ AuthGuard ],
        component: LayoutComponent,
        children: [
            { path: '', loadChildren: () => import('app/pages/secure-area/secure-area.routes') }
        ],
        resolve: {
            auth0: auth0Resolver
        }
    },
    {
        path: '**',
        redirectTo: '/open-area/not-found'
    }
];
