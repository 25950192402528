import { HttpClientModule } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { PreloadAllModules, provideRouter, withInMemoryScrolling, withPreloading } from '@angular/router';
import { appRoutes } from 'app/app.routes';
import { provideFuse } from "@fuse/fuse.provider";
import { getIconsProvider } from "@helpers/icons/icons.provider";
import { getHttpProviders } from "@helpers/providers/http.provider";
import { getNotificationProvider } from "@helpers/providers/notification.provider";
import { DialogsModule } from "@progress/kendo-angular-dialog";
import { getAuth0Provider } from "@helpers/providers/auth0.provider";

export const appConfig: ApplicationConfig = {
    providers: [
        importProvidersFrom(HttpClientModule),
        getHttpProviders(),
        provideAnimations(),
        getNotificationProvider(),
        getAuth0Provider(),
        provideRouter(appRoutes,
            withPreloading(PreloadAllModules),
            withInMemoryScrolling({ scrollPositionRestoration: 'enabled' }),
        ),
        getIconsProvider(),
        importProvidersFrom(DialogsModule),
        provideFuse({
            fuse: {
                layout: 'material',
                scheme: 'dark',
                screens: {
                    sm: '600px',
                    md: '960px',
                    lg: '1280px',
                    xl: '1440px',
                },
                theme: 'theme-brand',
                themes: [
                    {
                        id: 'theme-brand',
                        name: 'Brand',
                    }
                ]
            }
        }),
    ],
};
