import { NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterOutlet } from '@angular/router';
import { FuseFullscreenComponent } from '@fuse/components/fullscreen';
import { FuseLoadingBarComponent } from '@fuse/components/loading-bar';
import {
    FuseHorizontalNavigationComponent,
    FuseNavigationService,
    FuseVerticalNavigationComponent
} from '@fuse/components/navigation';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { UserComponent } from 'app/layout/common/user/user.component';
import { Subject, takeUntil } from 'rxjs';
import { AppNav } from "@models/shared/navigation.models";
import { NavigationService } from "@services/layout/navigation.service";
import { ThemeSwitchComponent } from "@layout/common/themeSwitch/theme-switch.component";
import { DialogsModule } from "@progress/kendo-angular-dialog";
import { ComboItemTemplateComponent } from "@shared/components/combo-item-template/combo-item-template.component";

@Component({
    selector: 'material-layout',
    templateUrl: './material.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [ FuseLoadingBarComponent, NgIf, FuseVerticalNavigationComponent, MatButtonModule, MatIconModule,
        FuseFullscreenComponent, UserComponent, FuseHorizontalNavigationComponent, RouterOutlet,
        ThemeSwitchComponent, DialogsModule, ComboItemTemplateComponent ],
})
export class MaterialLayoutComponent implements OnInit, OnDestroy {
    isScreenSmall: boolean;
    navigation: AppNav;
    private destroy: Subject<any> = new Subject<any>();

    constructor(
        private _navigationService: NavigationService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService
    ) {
    }

    get currentYear(): number {
        return new Date().getFullYear();
    }

    ngOnInit(): void {
        // Subscribe to navigation data
        this._navigationService
            .get()
            .pipe(takeUntil(this.destroy))
            .subscribe((navigation: AppNav) => {
                this.navigation = navigation;
            });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this.destroy))
            .subscribe(({ matchingAliases }) => {
                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this.destroy.next(null);
        this.destroy.complete();
    }

    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }
}
