import { AfterViewInit, Component, TemplateRef, ViewChild } from '@angular/core';
import { ElementReferenceService } from '@services/shared/element-reference.service';
import { NgForOf, NgIf } from "@angular/common";
import { ComboBoxModule } from "@progress/kendo-angular-dropdowns";

@Component({
    selector: 'combo-item-template',
    standalone: true,
    imports: [
        NgForOf,
        ComboBoxModule,
        NgIf
    ],
    templateUrl: './combo-item-template.component.html'
})
export class ComboItemTemplateComponent implements AfterViewInit {

    @ViewChild('itemTemplate') itemTemplate!: TemplateRef<any>;

    constructor(private elementReferenceService: ElementReferenceService) {
    }

    ngAfterViewInit() {
        this.elementReferenceService.setItemTemplate('comboTemplate', this.itemTemplate);
    }
}
