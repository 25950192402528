import { MenuItem } from '@fuse/components/navigation';

export const horizontalNavigation: MenuItem[] = [
    {
        id: 'itemSearches',
        title: 'Item Search List',
        type: 'basic',
        icon: 'heroicons_outline:list-bullet',
        link: '/secure-area/item-search-list',
        exactMatch: true
    },
    {
        id: 'itemSearch',
        title: 'Add/Update Item Search',
        type: 'basic',
        icon: 'heroicons_outline:book-open',
        link: '/secure-area/item-search-list/item-search-edit'
    },
    {
        id: 'recentAlerts',
        title: 'Recent Alerts',
        type: 'basic',
        icon: 'heroicons_outline:bell-alert',
        link: '/secure-area/recent-alert-list'
    },
    {
        id: 'appLog',
        title: 'Application Logs',
        type: 'basic',
        icon: 'heroicons_solid:document-check',
        link: '/secure-area/app-activity-log',
        permissions: [
            "admin:all"
        ]
    },
    {
        id: 'manageTasks',
        title: 'Scheduled Tasks',
        type: 'basic',
        icon: 'feather:git-branch',
        link: '/secure-area/scheduled-task-list',
        permissions: [
            "admin:all"
        ]
    },
    {
        id: 'manageUsers',
        title: 'Manage Users',
        type: 'basic',
        icon: 'heroicons_outline:user-group',
        link: '/secure-area/user-list',
        permissions: [
            "admin:all"
        ]
    }
];