import { UrlConfigModel } from '@models/shared/shared-models';

export const apiUrlStore: UrlConfigModel[] = [
    {
        alias: '@selfApi',
        overrideUrl: 'https://api.itempatrol.com',
        relativeUrl: "/api",
        authType: "patient_care_jwt"
    }
];
