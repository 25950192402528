import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    constructor(private toastService: ToastrService,
                private snackBar: MatSnackBar) {

    }

    success(message: string, title?: string) {
        this.snackBar.open(message, 'Close', {
            verticalPosition: 'top',
            horizontalPosition: 'center',
            duration: 2000
        });
    }

    info(message: string, title?: string) {
        this.snackBar.open(message, 'Close');
    }

    error(message: string, title?: string) {
        this.toastService.error(message, title);
    }
}
