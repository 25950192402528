<fuse-loading-bar></fuse-loading-bar>

<div class="flex flex-col flex-auto w-full">
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <div kendoDialogContainer></div>
        <combo-item-template></combo-item-template>
        <router-outlet *ngIf="true"></router-outlet>
    </div>
</div>
