import { Component, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { FuseLoadingBarComponent } from "@fuse/components/loading-bar";
import { FuseSplashScreenService } from "@fuse/services/splash-screen";
import { AppInitService } from "@services/shared/app-init.service";
import { Subject, takeUntil, tap } from "rxjs";
import { environment } from "@env/environment";
import { AppInfoModel, UrlConfigModel } from "@models/shared/shared-models";
import { CommonModule } from "@angular/common";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: [ './app.component.scss' ],
    standalone: true,
    imports: [ RouterOutlet, FuseLoadingBarComponent, CommonModule ],
})
export class AppComponent implements OnInit {
    loadingComplete = false;
    destroy$ = new Subject();

    constructor(
        private router: Router,
        private splashService: FuseSplashScreenService,
        private appInitService: AppInitService) {
    }

    ngOnInit(): void {
        this.initializeApp();
    }

    private initializeApp() {
        this.splashService.show(true);
        this.loadingComplete = false;

        this.appInitService
            .getBuildVersion()
            .pipe(takeUntil(this.destroy$))
            .pipe(tap(x => environment.appInfo = x))
            .subscribe(appInfo => this.setupUrl(appInfo));
    }

    private setupUrl(appInfo: AppInfoModel) {
        let tempList = [] as UrlConfigModel[];
        let urlStore = environment.urlStore;
        urlStore.forEach(urlModel =>
            this.appInitService
                .getUrlModel(appInfo, urlModel)
                .pipe(takeUntil(this.destroy$))
                .subscribe(value => {
                    tempList.push(value);
                    if (tempList.length == environment.urlStore.length) {
                        environment.urlStore = tempList;
                        this.loadingComplete = true;
                        this.splashService.hide(true);
                    }
                }));
    }
}
