import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { Router, RouterLink } from '@angular/router';
import { Subject } from 'rxjs';
import { UserModel } from "@models/open-area.models";
import { AuthService } from "@auth0/auth0-angular";
import { NgIf } from "@angular/common";

@Component({
    selector: 'user',
    templateUrl: 'user.component.html',
    standalone: true,
    imports: [
        MatIconModule,
        MatMenuModule,
        MatDividerModule,
        MatButtonModule,
        NgIf,
        RouterLink
    ],
})
export class UserComponent implements OnInit, OnDestroy {

    userData?: UserModel;
    private destroy$: Subject<any> = new Subject<any>();

    constructor(
        private router: Router,
        private authService: AuthService
    ) {
    }

    ngOnInit(): void {
        this.authService
            .user$
            .subscribe(x => {
                this.userData = {
                    isAuthenticated: true,
                    name: x.email,
                    picture: x.picture
                };
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next(null);
        this.destroy$.complete();
    }

    signOut(): void {
        this.authService.logout({
            logoutParams: {
                returnTo: window.location.origin
            }
        });
    }
}
