import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { mergeMap, Observable, of } from "rxjs";
import { State } from "@progress/kendo-data-query";
import { GridDataResult } from "@progress/kendo-angular-grid";
import { WebApiDataSourceResult } from "@models/shared/shared-models";
import { AuthService } from "@auth0/auth0-angular";
import { jwtDecode } from "jwt-decode";

@Injectable({
    providedIn: 'root'
})
export class UserAccountService {

    private url: string = "@selfApi/userService";

    constructor(private httpClient: HttpClient,
                private authService: AuthService) {
    }

    getAllPermissions() {
        return this.authService.getAccessTokenSilently()
            .pipe(mergeMap(token => {
                let decodedToken = jwtDecode(token);
                let permissions = decodedToken["permissions"] as string[];
                return of(permissions);
            }));
    }

    query(state?: State): Observable<GridDataResult> {
        let myUrl = this.url.combineUrl('/query');
        return this.httpClient
            .post<WebApiDataSourceResult<any>>(myUrl, state);
    }

    get(id: number) {
        let params = new HttpParams()
            .set('id', id ?? 0);

        return this.httpClient.get<any>(this.url,
            {
                params
            });
    }

    put(formVal: any) {
        return this.httpClient.put<any>(this.url, formVal);
    }

    delete(id: number) {
        let payLoad = {
            id: id
        };

        return this.httpClient.delete(this.url,
            {
                body: payLoad
            });
    }

    addUpdate(): Observable<any> {
        return this.httpClient.post<any>(this.url, {});
    }
}
