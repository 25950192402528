import { HTTP_INTERCEPTORS, provideHttpClient } from '@angular/common/http';
import { EnvironmentProviders, Provider } from '@angular/core';
import { UrlMagicHttpInterceptor } from "@helpers/interceptors/url-magic-http.interceptor";
import { AngularDateHttpInterceptor } from "@helpers/interceptors/angular-date-http.interceptor";
import { ErrorInterceptor } from "@helpers/interceptors/error.interceptor";
import { AppErrorService } from "@services/shared/app-error.service";
import { FromAdminInterceptor } from "@helpers/interceptors/from-admin.interceptor";

export const getHttpProviders = (): Array<Provider | EnvironmentProviders> => {
    return [
        provideHttpClient(),
        { provide: HTTP_INTERCEPTORS, useClass: AngularDateHttpInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, deps: [ AppErrorService ], multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: UrlMagicHttpInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: FromAdminInterceptor, multi: true }
    ];
};